import React from "react";
import { View, ScrollView } from "react-native";
import { StyledText as Text } from "../../StyledText";
import { useAppSelector } from "../../../redux/hooks";
import StyleSheet from "react-native-extended-stylesheet";
import useStyle from "./styles";
import { data } from "../../screens/Screener/data";
import Header from "../../navigation/Header";
import { CompanyData } from "constants/interfaces";

import TextStyles from "../../../components/global/text-styles";

export default function Table() {
  const { companiesFiltered } = useAppSelector(state => state.screener);
  const styles = useStyle();

  const columns = [
    "Ticker",
    "Weight*", // not in dataset
    //"Currency",
    //"Fiscal Year",
    //"Report Date",
    "Shares",
    "Net Income",
    "Depreciation & Amortization",
    "Interest Expense, Net",
    "Income Tax (Expense) Benefit, Net",
    "Open",
    "Low",
    "High",
    "Close"
  ];

  const createColumnHeaderComponent = (name: keyof CompanyData) => {
    return (
      <Text style={[styles.cell, styles.headCell, {fontFamily: 'Heebo_700Bold'}]} key={name}>
        {name.replace("__range", "").replaceAll("_", " ")}
        {name == 'weight' && <Text style={{color: '#FF0000'}}>*</Text>}
      </Text>
    )
  };

  const formatDisplay = (value: any) => {
    return typeof value === "number" ? value.toFixed(2) : value;
  }

  return (
    <View style={styles.container}>
      {companiesFiltered.length ? (
        <>
          <Text style={TextStyles.boldHeader}>Scanner output</Text>
          <ScrollView scrollEnabled horizontal>
            <View style={styles.wrapper}>
              <View style={styles.headRow}>
                {columns.map(name => createColumnHeaderComponent(name))}
              </View> 
              {companiesFiltered.map((el, index) => (
                <View
                  key={el.id}
                  style={StyleSheet.child(
                    styles,
                    "row",
                    index,
                    companiesFiltered.length,
                  )}>
                  {
                    columns.map(name => (
                      <Text style={styles.cell}>
                        {formatDisplay(el[name])}
                      </Text>)
                    )
                  }
                </View>
              ))}
            </View>
          </ScrollView>
          <Text>
              <Text style={{color: '#FF0000'}}>*</Text> Markowitz's weight
          </Text>
        </>
      ) : null}
    </View>
  );
}
