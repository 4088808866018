import { configureStore } from "@reduxjs/toolkit";
import screenerSlice from "./screenerSlice";
import authReducer from "./auth/authSlice";

export const store = configureStore({
  reducer: {
    screener: screenerSlice,
    auth: authReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["auth.user"],
        ignoredActionPaths: ["payload.user", "meta.arg"],
        ignoredActions: ["auth/handleAuthStateChange"],
      },
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
