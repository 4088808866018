import { Dimensions, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { colors } from "../../../constants/Colors";

export default function useStyles() {
  const insets = useSafeAreaInsets();
  return StyleSheet.create({
    wrapper: {
      backgroundColor: colors.backgroundNavbar,
      paddingHorizontal: 0,
      paddingVertical: 3,
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: Dimensions.get("screen").height * 0.05,
      maxHeight: insets.bottom + 75,
      minHeight: insets.bottom + 75,
      paddingBottom: insets.bottom + 8,
    },
  });
}
