import StyleSheet from "react-native-extended-stylesheet";
import { colors } from "../../../../constants/Colors";

export default function useStyle() {
  return StyleSheet.create({
    homeWrapper: {
      flexGrow: 1,
      justifyContent: "center",
      // alignItems: "center",
      paddingHorizontal: 24,
    },
    elementWrapper: {
      flexWrap: "wrap",
      flexDirection: "row",
    },
    wrapper: {
      width: "46%",
      margin: "2%",
    },
    box: {
      backgroundColor: colors.stroke,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      borderRadius: 16,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 50,
    },
    image: {
      width: 112,
      height: 112,
      marginBottom: 30,
      tintColor: colors.greenPressed,
    },
    boxText: {
      color: colors.greenPressed,
      fontSize: 16,
      fontFamily: "Heebo_700Bold",
      letterSpacing: 1,
    },
    boxLink: {
      width: "96%",
      justifyContent: "center",
      alignItems: "center",
      height: 56,
      borderRadius: 16,
      backgroundColor: colors.green,
      display: "flex",
      marginTop: 16,
      alignSelf: "center",
    },
    boxLinkText: {
      color: colors.background,
      fontSize: 18,
      fontFamily: "Heebo_700Bold",
      letterSpacing: 1,
    },
  });
}
