
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';


const options = [
  'forwardFill',
  'polynomialMethodFill',
  'quantifierSolution',
  'knn',
  'meanFill',
  'machineLearningFill',
  'medianFill',
];

type Props = {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
};

const SegmentedControl: React.FC<Props> = ({selectedOption, setSelectedOption} :Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedOption(options[0]);
  }, []);

  return (
    <View style={styles.segmentedControlContainer}>
      {options.map((option) => (
        <TouchableOpacity
          key={option}
          style={[
            styles.segment,
            selectedOption === option && styles.selectedSegment,
          ]}
          onPress={() => setSelectedOption(option)}
        >
          <Text
            style={[
              styles.segmentText,
              selectedOption === option && styles.selectedSegmentText,
            ]}
          >
            {t(`quantamentumView.missingDataMethodToggle.options.${option}`)}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  segmentedControlContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 10,
  },
  segment: {
    padding: 10,
    borderWidth: 1,
    borderColor: 'white',
    flex: 1,
    alignItems: 'center',
  },
  selectedSegment: {
    backgroundColor: 'white',
    borderRadius: 5,
  },
  segmentText: {
    color: 'white',
    textAlign: 'center',
  },
  selectedSegmentText: {
    color: '#003366',
  },
});

export default SegmentedControl;
