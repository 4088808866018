import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Platform,
  Pressable,
  ScrollView,
  Modal,
} from "react-native";
import Button from "../components/global/Button";
import { Picker } from "@react-native-picker/picker";
import DateTimePicker from "@react-native-community/datetimepicker";
import { StyleSheet, TextInput } from 'react-native';
import DatePicker from "react-datepicker";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import {
  getDcf,
  getFilteredPrice,
  getResultsDcf,
} from "../redux/thunks";
import { getCompanies } from "../redux/initialState";
import { Formik } from "formik";
// import { DCFform } from "../constants/interfaces";
import DCFTable from "../components/screens/Dcf/Table";
import ChartDCF from "../components/screens/Dcf/Chart";
import { Random } from "../helpers/datagen";
import * as Linking from "expo-linking";
import { useTranslation } from "react-i18next";
// import { signOut, getAuth } from "firebase/auth";

const styles = StyleSheet.create({
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: 'white',
  },
  sectionDescription: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: '400',
    color: 'white',
  },
  picker: {
    color: 'white', // White color for the picker text
    backgroundColor: '#333333', // Slightly lighter dark color for the picker
    borderRadius: 8,
    height: 40, // Fixed height for the picker
  },
});

const dcfPredictionsParametersSchema = [
    {name: 'beta'},
    {name: 'expected_market_return'},
    {name: 'risk_free_rate_of_return'},
    {name: 'current_assets_growth_rate'},
    {name: 'current_liabilities_growth_rate'},
]


export default function DcfScreen() {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedCompany, setSelectedCompany] = useState("Select company");
  const [dcfPredictionsParameters, setdcfPredictionsParameters] = useState<object>({});
  const [dcfPredictionsParametersFormOpen, setdcfPredictionsParametersFormOpen] = useState(false);
  const [formValues, setFormValues] = useState<any | undefined>(undefined);
  // useEffect(() => {
  //   signOut(getAuth());
  // }, []);

  // useEffect(() => {
  //   dispatch(getCompanies());
  // }, []);

  /*
  const { companies, dcf, resultsDcf, filteredPrice } = useAppSelector(
    state => state.screener,
  );
  */
  const companies = getCompanies()  // useAppSelector(state => state.screener.companies);
  const resultsDcf = useAppSelector(state => state.screener.resultsDcf);
  
  const baseDcf = new Random(selectedCompany, formValues?.period__range).inRange(0, 1000)
  const predictionDcfCoefficient = new Random(Object.values(dcfPredictionsParameters)).inRange(0.8, 1.2)
  const dcf = Math.round(baseDcf * predictionDcfCoefficient * 100) / 100
  
  const filteredPrice = [];

  useEffect(() => {
    console.log({ resultsDcf });
    console.log({ filteredPrice });
  }, [resultsDcf, filteredPrice]);

  useEffect(() => {
    console.log({ companies });
  }, [companies]);

  return (
    <ScrollView style={{ paddingHorizontal: 36 }}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View
          style={{
            position: "relative",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <View
            style={{
              position: "absolute",
              opacity: 0.5,
              backgroundColor: "#181ADD",
              height: "100%",
              width: "100%",
            }}
          />
          <View
            style={{
              backgroundColor: "#f2f2f2",
              paddingHorizontal: 60,
              paddingVertical: 40,
            }}>
            <Text
              style={{
                fontSize: 21,
                marginBottom: 60,
                textAlign: "center",
              }}>
              {t("dcfView.standardDcfModal.title")}
            </Text>
            <Text
              style={{ textAlign: "center", fontSize: 18, fontWeight: "300" }}>
              {t("dcfView.standardDcfModal.preValueText")}
            </Text>
            <Text
              style={{
                textAlign: "center",
                color: "#181ADD",
                fontSize: 19,
                fontWeight: "bold",
                marginBottom: 40,
              }}>
              {dcf}
            </Text>
            <Pressable onPress={() => setModalVisible(!modalVisible)}>
              <Button text={t("dcfView.standardDcfModal.okButton")} variant="primary" />
            </Pressable>
          </View>
        </View>
      </Modal>
      {(
        <>
          <Formik
            initialValues={{
              company_id: "",
              period__range: [new Date(), new Date()],
            }}
            onSubmit={values => {
              setFormValues(values);
              dispatch(getResultsDcf(values));
              dispatch(getDcf(values));
              dispatch(getFilteredPrice(values));
            }}>
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              setFieldValue,
            }) => {
              return (
                <>
                  <View
                    style={{
                      paddingTop: 30,
                      paddingBottom: 60,
                      position: "relative",
                      zIndex: 10,
                      alignItems: "center",
                    }}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}>
                      <Text style={styles.sectionTitle}>
                        {t("dcfView.company")}
                      </Text>
                      <Text style={styles.sectionDescription} >
                      {t("dcfView.selectCompany")}
                      </Text>
                      
                      <Picker
                        style={styles.picker}
                        selectedValue={selectedCompany}
                        onValueChange={itemValue => {
                          setFieldValue("company_id", parseInt(itemValue, 10));
                          setSelectedCompany(itemValue);
                        }}>
                        <Picker.Item
                          label={t("dcfView.selectCompany")}
                          value={t("dcfView.selectCompany")}
                        />
                        {companies.map(el => (
                          <Picker.Item
                            key={el.name}
                            label={el.name}
                            value={el.id}
                          />
                        ))}
                      </Picker>
                    </View>

                    <Text
                      style={{
                        textAlign: "center",
                        paddingTop: 15,
                        paddingBottom: 10,
                      }}>
                      {t("dcfView.startDate")}
                    </Text>
                    {Platform.OS === "web" ? (
                      <View style={{ zIndex: 3 }}>
                        <DatePicker
                          selected={values.period__range[0]}
                          onChange={value =>
                            setFieldValue("period__range[0]", value)
                          }
                        />
                      </View>
                    ) : (
                      <>
                        <DateTimePicker
                          value={values.period__range[0]}
                          onChange={(_: unknown, date: Date | undefined) =>
                            setFieldValue("period__range[0]", date)
                          }
                          display="default"
                          is24Hour={true}
                          mode={"date"}
                          style={{ width: 70, marginBottom: 20 }}
                        />
                      </>
                    )}

                    <Text
                      style={{
                        textAlign: "center",
                        paddingTop: 15,
                        paddingBottom: 10,
                      }}>
                      {t("dcfView.endDate")}
                    </Text>
                    {Platform.OS === "web" ? (
                      <View>
                        <DatePicker
                          selected={values.period__range[1]}
                          onChange={value =>
                            setFieldValue("period__range[1]", value)
                          }
                        />
                      </View>
                    ) : (
                      <>
                        <DateTimePicker
                          value={values.period__range[1]}
                          onChange={(_: unknown, date: Date | undefined) =>
                            setFieldValue("period__range[1]", date)
                          }
                          display="default"
                          is24Hour={true}
                          mode={"date"}
                          style={{ width: 70, marginBottom: 20 }}
                        />
                      </>
                    )}
                  </View>
                  <Pressable
                    onPress={() => {
                      handleSubmit();
                      // navigation.navigate("Modal");
                      setModalVisible(!modalVisible);
                    }}>
                    <Button
                      text={t("dcfView.buttons.calculateStandardDcf")}
                      variant={"primary"}
                    />
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setdcfPredictionsParametersFormOpen(dcfPredictionsParametersFormOpen => !dcfPredictionsParametersFormOpen);
                    }}>
                    <Button
                      text={t("dcfView.buttons.calculateDcfNnFutureFinancialDataPredictions")}
                      variant={"secondary"}
                    />
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      Linking.openURL("https://quantifier.ai/assets/DCF.xlsx");
                    }}>
                    <Button
                      text={t("dcfView.buttons.downloadExcelFormulas")}
                      variant={"secondary"}
                    />
                  </Pressable>

                </>
              );
            }}
          </Formik>
          {dcfPredictionsParametersFormOpen && 
            <>
              {dcfPredictionsParametersSchema.map((el, index) => {
                const label = t(`dcfView.predictedDcf.${el.name}`);
                return (
                  <View key={index}>
                    <Text style={styles.sectionTitle}>{label}</Text>
                    <TextInput
                      style={{ height: 40, borderColor: 'gray', borderWidth: 1, ...styles.sectionDescription }}
                      key={index}
                      //style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
                      onChangeText={text => setdcfPredictionsParameters({...dcfPredictionsParameters, [el.name]: text})}
                      value={dcfPredictionsParameters[el.name]}
                      placeholder={`Insert ${label}...`}
                      keyboardType="numeric"
                    />
                  </View>
                )
              })}
            <Pressable
              onPress={() => {
                console.log("Calculating for:", dcfPredictionsParameters);
                /* TODO
                - update resultsDcf for DCTFTable
                - update filteredPrice for ChartDCF
                - update dcf for modal
                */
                setModalVisible(true)
              }}>
              <Button
                text={t("dcfView.predictedDcf.predictButton")}
                variant={"primary"}
              />
            </Pressable>
            </>
          }
        </>
      )}
      {resultsDcf.length ? (
        <>
          <Text style={styles.sectionTitle}>
            { companies.find(company => selectedCompany == company.id)?.name }
          </Text>
          <DCFTable companies={[]} data={resultsDcf} />
        </>
      ) : null}
      <ChartDCF />
      <View style={{margin: "auto", width: "90%", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <Text style={{color:"#fff"}}>{t("dcfView.description")}</Text>
      </View>
    </ScrollView>
  );
}
