import StyleSheet from "react-native-extended-stylesheet";

export default function useStyle() {
  return StyleSheet.create({
    container: {
      marginTop: "4.7 rem",
      paddingBottom: "1 rem",
    },
    wrapper: {
      marginTop: "1.4 rem",
    },
    headRow: {
      flexDirection: "row",
      paddingVertical: "1 rem",
      paddingLeft: "0.5 rem",
    },
    headCell: {
      color: "#9A99A2",
    },
    row: {
      flexDirection: "row",
      paddingVertical: "1 rem",
      paddingLeft: "0.5 rem",
    },
    "row:nth-child-even": {
      backgroundColor: "#e8e9f0",
    },
    cell: {
      minWidth: "8.35 rem",
      maxWidth: "8.35 rem",
      alignItems: "center",
      display: "flex",
    },
  });
}
