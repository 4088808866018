import { Pressable, View, Image, Text } from "react-native";
import Box from "../components/screens/Home/Box";
import { HomeNavProps } from "../navigation/types";
import useStyle from "../components/screens/Home/Box/styles";
import { Link } from "@react-navigation/native";
import { useTranslation } from "react-i18next";

export default function HomeScreen({ navigation }: HomeNavProps) {
  const styles = useStyle();
  const { t } = useTranslation();
  return (
    <View style={styles.homeWrapper}>
      <View style={styles.elementWrapper}>
        <Pressable
          style={styles.wrapper}
          onPress={() => navigation.navigate("DCFScreen")}>
          <Box name={t("homeView.dcf")}>
            <Image
              style={styles.image}
              source={require("../assets/images/dcf.png")}
            />
          </Box>
        </Pressable>
        <Pressable
          style={styles.wrapper}
          onPress={() => navigation.navigate("ScreenerScreen")}>
          <Box name={t("homeView.screener")}>
            <Image
              style={styles.image}
              source={require("../assets/images/screener.png")}
            />
          </Box>
        </Pressable>
        <Pressable
          style={styles.wrapper}
          onPress={() => navigation.navigate("QuantamentalScreen")}>
          <Box name={t("homeView.quantamental")}>
            <Image
              style={styles.image}
              source={require("../assets/images/qmntl.png")}
            />
          </Box>
        </Pressable>
        <Pressable
          style={styles.wrapper}
          onPress={() => navigation.navigate("SettingsScreen")}>
          <Box name={t("homeView.manageAccount")}>
            <Image
              style={styles.image}
              source={require("../assets/images/setting.png")}
            />
          </Box>
        </Pressable>
      </View>
      <Link style={styles.boxLink} to={"https://envirly.com/"}>
        <Text style={styles.boxLinkText}>{t("homeView.goToEnvirly")}</Text>
      </Link>
    </View>
  );
}
