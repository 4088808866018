import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getFilteredCompanies,
  getBacktest,
  getCompanies,
  getResultsDcf,
  getDcf,
  getFilteredPrice,
} from "./thunks";
import { StateProps, Company } from "../constants/interfaces";
import { initialState } from "./initialState";

const screenerSlice = createSlice({
  name: "screener",
  initialState,
  reducers: {
    setCompanies(state: StateProps, action: PayloadAction<Company[]>) {
      state.companies = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFilteredCompanies.fulfilled, (state, action) => {
        if (action.payload) {
          state.companiesFiltered = action.payload;
          state.loading = false;
        }
      })
      .addCase(getBacktest.fulfilled, (state, action) => {
        if (action.payload) {
          state.backtest = action.payload;
          state.loading = false;
        }
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        if (action.payload) {
          state.companies = action.payload;
          state.loading = false;
        }
      })
      .addCase(getResultsDcf.fulfilled, (state, action) => {
        if (action.payload) {
          state.resultsDcf = action.payload;
          state.loading = false;
        }
      })
      .addCase(getDcf.fulfilled, (state, action) => {
        if (action.payload) {
          state.dcf = action.payload;
          state.loading = false;
        }
      })
      .addCase(getFilteredPrice.fulfilled, (state, action) => {
        if (action.payload) {
          state.filteredPrice = action.payload;
          state.loading = false;
        }
      });
  },
});

export const { setCompanies } = screenerSlice.actions;

export default screenerSlice.reducer;
