import React from "react";
import { Image, Pressable, Text } from "react-native";
import styles from "./styles";
import RouteConfig from "../../../constants/RouteConfig";
import { colors } from "../../../constants/Colors";

export default function BottomTabIcon({
  route,
  index,
  state,
  navigation,
}: any) {
  // TODO: add proper interface
  const isFocused = state.index === index;

  const routeConfig = RouteConfig();

  const onPress = () => {
    const event = navigation.emit({
      type: "tabPress",
      target: route.key,
      canPreventDefault: true,
    });

    if (!isFocused && !event.defaultPrevented) {
      navigation.navigate({ name: route.name, merge: true });
    }
  };
  return (
    <Pressable onPress={onPress} key={index} style={styles.wrapper}>
      <Image
        source={routeConfig[route.name as keyof typeof routeConfig].image}
        style={[
          styles.image,

          { tintColor: isFocused ? colors.green : colors.white },
        ]}
        fadeDuration={0}
      />
      <Text
        style={[
          styles.text,
          { color: isFocused ? colors.green : colors.white },
        ]}>
        {routeConfig[route.name as keyof typeof routeConfig].name}
      </Text>
    </Pressable>
  );
}
