import React, { useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  TextInput,
  Pressable,
} from "react-native";
import { colors } from "../constants/Colors";
import { useTranslation } from "react-i18next";

export default function SignInScreen() {
  const { t }= useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.welcome}>{t("signInView.welcomeTo")}</Text>
        <Image
          style={styles.image}
          source={require("../assets/images/logo.png")}
        />

        <Text style={styles.login}>{t("signInView.logIn")}</Text>
        <Text style={styles.label}>{t("signInView.email")}</Text>
        <TextInput
          style={styles.input}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />

        <Text style={styles.label}>{t("signInView.password")}</Text>
        <TextInput
          style={styles.input}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />

        <Pressable
          onPress={() => setToggleCheckBox(!toggleCheckBox)}
          style={styles.checkboxWrapper}>
          <Text>{toggleCheckBox ? "👍" : "👎"}</Text>
          <Text style={styles.terms}>
          {t("signInView.termsAndConditionsCheck")}
          </Text>
        </Pressable>
      </View>
      <View>
        <Pressable
          onPress={() => {
            console.log("click");
          }}>
          <Text>{t("signInView.logIn")}</Text>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  welcome: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Heebo_700Bold",
    letterSpacing: 1,
    color: colors.white,
    marginBottom: 18,
  },
  login: {
    fontSize: 24,
    textAlign: "left",
    fontFamily: "Heebo_700Bold",
    letterSpacing: 1,
    color: colors.white,
    marginBottom: 12,
  },
  label: {
    fontSize: 16,
    textAlign: "left",
    fontFamily: "Heebo_700Bold",
    letterSpacing: 1,
    color: colors.white,
    marginBottom: 12,
  },
  input: {
    borderRadius: 16,
    backgroundColor: colors.stroke,
    borderColor: colors.grey,
    borderWidth: 2,
    height: 56,
    paddingLeft: 16,
    color: colors.white,
    marginBottom: 18,
  },
  image: {
    height: 24,
    width: 240,
    resizeMode: "cover",
    marginBottom: 42,
    alignSelf: "center",
  },
  checkboxWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  terms: {
    fontSize: 16,
    textAlign: "left",
    fontFamily: "Heebo_400Regular",
    // letterSpacing: 1,
    color: colors.white,
    marginLeft: 14,
  },
});
