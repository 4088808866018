import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
// import { setCompanies } from "./screenerSlice";
import {
  Backtest,
  Company,
  CompanyData,
  DCFform,
  Price,
  ResultDcf,
  ScreenerForm,
} from "../constants/interfaces";
import { Random, generateBacktest, generateScreenerCompanyData } from "../helpers/datagen";
import data from '../data.json'
import { companies } from "./initialState";

const useMockedValues = true;

export const getFilteredCompanies = createAsyncThunk(
  "setCompanies",
  async (values: ScreenerForm) => {
    if (useMockedValues) {
      const seed = JSON.stringify(values);
      
      const random = new Random(seed);
      const numberOfTickers = random.inRangeInt(2, 8);
      const _tickers = Array.from({ length: numberOfTickers }, () =>
        random.choice(companies).ticker
      )
      const tickers = [...new Set(_tickers)]
      
      console.log(tickers)
      
      let dataToDisplay = {}
      data.data.forEach(row => {
        const ticker = row[0];
        if (!tickers.includes(ticker)) {
          return
        }
        let entry = Object.fromEntries(row.map((el, i) => [data.columns[i], el]))
        entry['Weight*'] = Math.round(random.inRange(0, 1) * 100) / 100
        dataToDisplay[ticker] = entry 
      })
      dataToDisplay = Object.values(dataToDisplay)
      console.log(dataToDisplay)
      return dataToDisplay

      /*
      return [
        generateScreenerCompanyData("Apple", "AAPL", 2020, seed),
        generateScreenerCompanyData("Microsoft", "MSFT", 2020, seed),
        generateScreenerCompanyData("Tesla", "TSL", 2020, seed),
        generateScreenerCompanyData("Alphabet", "GOOGL", 2020, seed),
        generateScreenerCompanyData("Amazon", "AMZN", 2020, seed),
      ];
      */
    }

    const acceptedValues = [""];
    const filteredValue = Object.fromEntries(
      Object.entries(values).filter(
        ([el1, el2]) =>
          el1 !== "start_dt" &&
          el1 !== "position_duration" &&
          !el2.every((elem: string) => acceptedValues.includes(elem)),
      ),
    );
    try {
      return (await api.getFilteredCompanies(filteredValue)) as CompanyData[];
    } catch (e) {
      console.log(e);
    }
      
  },
);

export const getBacktest = createAsyncThunk(
  "getBacktest",
  async (values: ScreenerForm) => {
    if(useMockedValues) {
      return generateBacktest(2022, JSON.stringify(values)); 
    }
    const acceptedValues = [""];
    const company_filter_schema = Object.fromEntries(
      Object.entries(values).filter(
        ([el1, el2]) =>
          el1 !== "start_dt" &&
          el1 !== "position_duration" &&
          !el2.every((elem: string) => acceptedValues.includes(elem)),
      ),
    );
    const start_dt = Object.fromEntries(
      Object.entries(values).filter(([el1]) => el1 === "start_dt"),
    );

    const position_duration = Object.fromEntries(
      Object.entries(values).filter(([el1]) => el1 === "position_duration"),
    );

    const filteredValue = {
      company_filter_schema,
      start_dt: new Date(start_dt.start_dt as Date).toISOString().slice(0, 19),
      position_duration:
        parseInt(<string>position_duration.position_duration, 10) * 86400, // mnozymy razy 86400 by dostac wynik dzienny a nie sekundowy
    };

    try {
      return (await api.getBacktest(filteredValue)) as Backtest;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCompanies = createAsyncThunk("getCompanies", async () => {
  try {
    return (await api.getCompanies()) as Company[];
  } catch (e) {
    console.log(e);
  }
});

export const getResultsDcf = createAsyncThunk(
  "getResultsDcf",
  async (values: DCFform) => {
    const company_id = Object.fromEntries(
      Object.entries(values).filter(([el1]) => el1 === "company_id"),
    );
    const period__range = Object.fromEntries(
      Object.entries(values).filter(([el1]) => el1 === "period__range"),
    );

    const filteredValue = {
      company_id: company_id.company_id,
      period__range: [
        new Date(period__range.period__range[0] as Date).getFullYear(),
        new Date(period__range.period__range[1] as Date).getFullYear(),
      ],
    };
    try {
      return (await api.getResultDcf(filteredValue)) as ResultDcf[];
    } catch (e) {
      console.log(e);
    }
  },
);

export const getDcf = createAsyncThunk("getDcf", async (values: DCFform) => {
  const company_id = Object.fromEntries(
    Object.entries(values).filter(([el1]) => el1 === "company_id"),
  );
  const period__range = Object.fromEntries(
    Object.entries(values).filter(([el1]) => el1 === "period__range"),
  );

  const filteredValue = {
    company_id: company_id.company_id,
    period__range: [
      new Date(period__range.period__range[0] as Date).getFullYear(),
      new Date(period__range.period__range[1] as Date).getFullYear(),
    ],
  };
  try {
    return (await api.getDcf(filteredValue)) as string;
  } catch (e) {
    console.log(e);
  }
});

export const getFilteredPrice = createAsyncThunk(
  "getFilteredPrice",
  async (values: DCFform) => {
    const company_id = Object.fromEntries(
      Object.entries(values).filter(([el1]) => el1 === "company_id"),
    );
    const period__range = Object.fromEntries(
      Object.entries(values).filter(([el1]) => el1 === "period__range"),
    );

    const filteredValue = {
      company_id__in: [company_id.company_id],
      time__range: [
        new Date(period__range.period__range[0] as Date)
          .toISOString()
          .slice(0, 19),
        new Date(period__range.period__range[1] as Date)
          .toISOString()
          .slice(0, 19),
      ],
    };
    try {
      return (await api.getFilteredPrice(filteredValue)) as Price[];
    } catch (e) {
      console.log(e);
    }
  },
);
