import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  ScrollView,
  Pressable,
} from "react-native";
import { StyledText as Text } from "../../../components/StyledText";
import { TextInput, useThemeColor } from "../../../components/Themed";

import DateTimePicker from "@react-native-community/datetimepicker";
import DatePicker from "react-datepicker";
import useStyle from "./styles";
import { Formik } from "formik";
import Button from "../../global/Button";
import { data } from "./data";
import InputWrapper from "../../global/InputWrapper";
import { getFilteredCompanies, getBacktest } from "../../../redux/thunks";
import { useAppDispatch } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

export default function Scanner() {
  const styles = useStyle();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const highlightColor = useThemeColor({}, "tint");

  const [handleForm, setHandleForm] = useState({
    eventSource: "",
    handleSubmit: () => {},
  });

  useEffect(() => {
    if (handleForm.eventSource !== "") {
      handleForm.handleSubmit();
    }
  }, [handleForm]);

  return (
    <ScrollView>
      <Formik
        initialValues={{
          ...data,
          start_dt: new Date(),
          position_duration: "",
          filter_expression: "*",
        }}
        onSubmit={values => {
            dispatch(getFilteredCompanies(values));
            dispatch(getBacktest(values));
        }}>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          setFieldValue,
        }) => {
          return (
            <View style={{ padding: 20 }}>
              <View style={{ justifyContent: "center", alignItems: "center"}}>
                <Text
                  style={{
                    paddingTop: 40,
                    paddingBottom: 10,
                    fontSize: 21,
                    textAlign: "center",
                    fontFamily: "Heebo_700Bold"
                  }}>
                  {t("screenerView.selectStartDateAndDurationPeriod")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    paddingTop: 15,
                    paddingBottom: 10,
                  }}>
                  {t("screenerView.startDate")}
                </Text>
                {Platform.OS === "web" ? (
                  <View style={{ zIndex: 3 }}>
                    <DatePicker
                      selected={values.start_dt}
                      onChange={value => setFieldValue("start_dt", value)}
                    />
                  </View>
                ) : (
                  <>
                    <DateTimePicker
                      value={values.start_dt}
                      onChange={(_: unknown, date: Date | undefined) =>
                        setFieldValue("start_dt", date)
                      }
                      display="default"
                      is24Hour={true}
                      mode={"date"}
                      style={{ width: 70, marginBottom: 20 }}
                    />
                  </>
                )}
                <Text style={{ textAlign: "center", paddingBottom: 10, paddingTop: 20 }}>
                {t("screenerView.durationPeriod")}
                </Text>

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TextInput
                    placeholder={t("screenerView.numberOfDays")}
                    value={values.position_duration}
                    onChangeText={value =>
                      setFieldValue("position_duration", value)
                    }
                    onBlur={handleBlur("position_duration")}
                    style={{
                      borderColor: "#C1C1C1",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderRadius: 6,
                      padding: 14,
                      width: 130,
                      marginRight: 10,
                    }}
                  />
                  <Text>{t("screenerView.days")}</Text>
                </View>
              </View>

              <Text
                style={{
                  paddingTop: 40,
                  paddingBottom: 10,
                  fontSize: 21,
                  textAlign: "center",
                  fontFamily: "Heebo_700Bold"
                }}>
                {t("screenerView.pleaseInputRangeOfValues")}
              </Text>
              <Text style={{ textAlign: "center", paddingBottom: 30 }}>
                <Text style={{ color: highlightColor }}>*</Text>
                {t("screenerView.notAllFieldsRequiredDisclaimer")}
              </Text>

              <View>
                <Text style={styles.label}>{t("screenerView.filterExpression")}</Text>
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={"*"}
                    onChangeText={(value: string) =>
                      setFieldValue('filter_expression', value)
                    }
                    onBlur={handleBlur('filter_expression')}
                    value={values['filter_expression']}
                  />
                </View>
              </View>

              {Object.keys(data).map(el => (
                <InputWrapper
                  setFieldValue={setFieldValue}
                  key={el}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  text={el}
                  values={values}
                />
              ))}

              

              <Pressable
                onPress={() =>
                  setHandleForm({ handleSubmit, eventSource: "button1" })
                }>
                <Button text={t("screenerView.scanAndBacktestButton")} variant={"primary"} />
              </Pressable>

              {/* <Pressable
                onPress={() =>
                  setHandleForm({ handleSubmit, eventSource: "button2" })
                }>
                <Button text={"Backtest"} variant={"secondary"} />
              </Pressable> */}
            </View>
          );
        }}
      </Formik>
      <View style={{margin: "auto", width: "90%", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <Text style={{color:"#fff"}}>{t("screenerView.description")}</Text>
      </View>
    </ScrollView>
  );
}
