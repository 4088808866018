import { ScrollView, StyleSheet } from "react-native";
import Scanner from "../components/screens/Screener";
import Table from "../components/global/Table";
import Chart from "../components/global/Chart";

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    paddingHorizontal: .8,
    // maxWidth: 600,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  // "@media (min-width: 350) and (max-width: 500)": {
  //   container: {
  //     backgroundColor: "green",
  //   },
  // },
});


export default function ScreenerScreen() {
  return (
    <ScrollView>
      <Scanner/>
      <Table/>
      <Chart/>
    </ScrollView>
  );
}

