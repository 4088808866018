import { useTranslation } from "react-i18next";

export default function routeConfig() {
  const { t } = useTranslation();
  return {
    HomeScreen: {
      image: require("../assets/images/home.png"),
      name: t("bottomToolbar.home"),
    },
    DCFScreen: {
      image: require("../assets/images/dcf.png"),
      name: t("bottomToolbar.dcf")
    },
    ScreenerScreen: {
      image: require("../assets/images/screener.png"),
      name: t("bottomToolbar.screener")
    },
    SettingsScreen: {
      image: require("../assets/images/setting.png"),
      name: t("bottomToolbar.settings")
    },
    QuantamentalScreen: {
      image: require("../assets/images/qmntl.png"),
      name: t("bottomToolbar.quantamentum")
    },
    // SignUpScreen: {
    //   image: require("../assets/images/qmntl.png"),
    //   name: "SignUp",
    // },
    SignInScreen: {
      image: require("../assets/images/qmntl.png"),
      name: t("bottomToolbar.signin")
    },
  };
};
