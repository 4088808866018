import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./interfaces";
import { User } from "firebase/auth";


export const handleAuthStateChange = createAsyncThunk(
    "auth/handleAuthChange",
    async (user: User | null) => {
        if (user) {
            const token = await user.getIdToken(true);
            return { user, token };
        } else return null;
    }
);

export const refreshToken = createAsyncThunk(
    "auth/refreshToken",
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        if (state.auth.user) return await state.auth.user.getIdToken();
        else return "";
    }
);