async function http<T>(input: RequestInfo, init?: RequestInit) {
  const request = await fetch(input, init);
  if (request.ok) {
    if (request.status !== 204) {
      return (await request.json()) as T;
    } else {
      return {} as T;
    }
  } else {
    throw request as Response;
  }
}

export default http;
