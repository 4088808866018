import StyleSheet from "react-native-extended-stylesheet";

export default function useStyle() {
  return StyleSheet.create({
    title: {
      fontSize: 30,
      //color: "#181AD4",
      textAlign: "center",
      marginBottom: 10,
      marginTop: 30,
    },
    subTitle: {
      fontSize: 21,
      textAlign: "center",
      // backgroundColor: "#f2f2f2",
      // zIndex: 2,
      // width: "70%",
    },
    lineWrapper: {
      // position: "relative",
      // alignItems: "center",
    },
    line: {
      // height: 1,
      // width: "100%",
      // backgroundColor: "#000",
      // position: "absolute",
      // top: "50%",
      // transform: [{ translateY: 1 }],
    },
  });
}
