import React from "react";
import { ScrollView, Text, View } from "react-native";
import { DCFTableProps } from "../../../../constants/interfaces";
import StyleSheet from "react-native-extended-stylesheet";
import useStyle from "./styles";
import Header from "../../../navigation/Header";
// import {data} from "../../Screener/data";

export default function DCFTable({ data, companies }: DCFTableProps) {
  const styles = useStyle();
  // useEffect(() => {
  //   console.log({ companies });
  //   const company = companies.find(elem => elem.id === data[0].company_id);
  //   console.log({ company });
  // }, []);

  const company = companies.find(elem => elem.id === data[0].company_id);
  return (
    <View style={styles.container}>
      <Header title={company?.name} subtitle="financial data" />
      <ScrollView scrollEnabled horizontal>
        <View style={styles.wrapper}>
          <View style={styles.headRow}>
            <Text style={[styles.cell, styles.headCell]}>Period</Text>
            <Text style={[styles.cell, styles.headCell]}>Sales</Text>
            <Text style={[styles.cell, styles.headCell]}>Current Assets</Text>
            <Text style={[styles.cell, styles.headCell]}>Ebit</Text>
            <Text style={[styles.cell, styles.headCell]}>Tax Payment</Text>
            <Text style={[styles.cell, styles.headCell]}>Amortization</Text>
            <Text style={[styles.cell, styles.headCell]}>
              Net Working Capital
            </Text>
            <Text style={[styles.cell, styles.headCell]}>
              Noncurrent Assets
            </Text>
            <Text style={[styles.cell, styles.headCell]}>
              Current Liabilities
            </Text>
            <Text style={[styles.cell, styles.headCell]}>
              Noncurrent Liabilities
            </Text>
            <Text style={[styles.cell, styles.headCell]}>Financial Costs</Text>
            <Text style={[styles.cell, styles.headCell]}>Capex</Text>
            <Text style={[styles.cell, styles.headCell]}>Equity</Text>
            <Text style={[styles.cell, styles.headCell]}>Debt</Text>
          </View>
          {data.map((el, index) => (
            <View
              key={index}
              style={StyleSheet.child(styles, "row", index, data.length)}>
              <Text style={styles.cell}>{el.period}</Text>
              <Text style={styles.cell}>{el.sales}</Text>
              <Text style={styles.cell}>{el.current_assets}</Text>
              <Text style={styles.cell}>{el.ebit}</Text>
              <Text style={styles.cell}>{el.tax_payment}</Text>
              <Text style={styles.cell}>{el.amortization}</Text>
              <Text style={styles.cell}>{el.net_working_capital}</Text>
              <Text style={styles.cell}>{el.noncurrent_assets}</Text>
              <Text style={styles.cell}>{el.current_liabilities}</Text>
              <Text style={styles.cell}>{el.noncurrent_liabilities}</Text>
              <Text style={styles.cell}>{el.financial_costs}</Text>
              <Text style={styles.cell}>{el.capex}</Text>
              <Text style={styles.cell}>{el.equity}</Text>
              <Text style={styles.cell}>{el.debt}</Text>
            </View>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}
