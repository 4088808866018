import React from "react";
import { View } from "react-native";
import { Dimensions } from "react-native";
import { useAppSelector } from "../../../redux/hooks";
import {Text} from "../../../components/Themed";
import Header from "../../navigation/Header";
import { VictoryLine, VictoryChart, VictoryAxis } from "victory-native";
import { useThemeColor } from "../../../components/Themed";
import TextStyles from "../../../components/global/text-styles";

export default function Chart() {
  const screenWidth = Dimensions.get("window").width;
  const { backtest } = useAppSelector(state => state.screener);
  const dataColor = useThemeColor({}, "text");
  const bgColor = useThemeColor({}, 'background');
  const data = 
    Object.entries(backtest).map(([k, v]) => ({ x: new Date(k).toLocaleDateString(), y: v }));


  return (
    <View>
      {Object.keys(backtest).length ? (
        <>
          <Text style={TextStyles.boldHeader}>Portfolio's performance</Text>
          <VictoryChart>
            <VictoryLine
              style={{
                data: { stroke: "#181AD4", color: "#181AD4", },
                parent: { border: "1px solid #ccc", backgroundColor: "#fff", paddingBottom: 40 },
                labels: { fontSize: 50, color: dataColor },
              }}
              interpolation={"natural"}
              width={screenWidth}
              height={300}
              data={data}
              />
              <VictoryAxis
                style={{
                  axis: {stroke: dataColor},
                  tickLabels: {fill: dataColor},
                }}
                dependentAxis
              />
              <VictoryAxis
                tickCount={5}
                style={{
                  axis: {stroke: dataColor},
                  ticks: {fill: dataColor, stroke: dataColor},
                  tickLabels: {fill: dataColor, angle: -45},
                }}
              />
          </VictoryChart>
        </>
      ) : null}
    </View>
  );
}
