import React from "react";
import { View } from "react-native";
import { StyledText as Text } from "../../../components/StyledText";
import { TextInput } from "../../../components/Themed";
import { InputWrapperProps } from "../../../constants/interfaces";
import useStyle from "./styles";
import { useTranslation } from "react-i18next";

export default function InputWrapper({
  values,
  handleBlur,
  text,
  setFieldValue,
}: InputWrapperProps) {
  const styles = useStyle();
  const { t } = useTranslation();
  const label = t(`screenerView.inputs.${text.replace("__range", "")}`);
  return (
    <View style={styles.inputPairContainer}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.inputContainer}>
        <TextInput
          style={styles.input}
          placeholder={"-"}
          onChangeText={(value: string) =>
            setFieldValue(`${text}[0]`, parseInt(value, 10))
          }
          onBlur={handleBlur(`${text}[0]`)}
          value={values[text][0]}
          keyboardType="numeric"
        />
        <Text> {t("screenerView.to")} </Text>
        <TextInput
          style={styles.input}
          placeholder={"-"}
          onChangeText={(value: string) =>
            setFieldValue(`${text}[1]`, parseInt(value, 10))
          }
          onBlur={handleBlur(`${text}[1]`)}
          value={values[text][1]}
          keyboardType="numeric"
        />
      </View>
    </View>
  );
}
