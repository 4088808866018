export const data = {
  total_assets__range: ["", ""],
  fixed_assets__range: ["", ""],
  tangible_assets__range: ["", ""],
  intangible_assets__range: ["", ""],
  goodwill__range: ["", ""],
  current_assets__range: ["", ""],
  inventories__range: ["", ""],
  short_term_receivables__range: ["", ""],
  cash__range: ["", ""],
  equity__range: ["", ""],
  minority_interest__range: ["", ""],
  reserves_and_other__range: ["", ""],
  long_term_liabilities__range: ["", ""],
  long_term_debt__range: ["", ""],
  long_term_lease__range: ["", ""],
  short_term_liabilities__range: ["", ""],
  short_term_trade_liabilities__range: ["", ""],
  short_term_debt__range: ["", ""],
  short_term_lease__range: ["", ""],
  revenue__range: ["", ""],
  cogs__range: ["", ""],
  sga_expense__range: ["", ""],
  other_operating_income__range: ["", ""],
  other_operating_expense__range: ["", ""],
  financial_income__range: ["", ""],
  financial_expense__range: ["", ""],
  other_income_before_tax__range: ["", ""],
  tax__range: ["", ""],
  off_PL_income__range: ["", ""],
  accrual_income__range: ["", ""],
  deprecation_tangible__range: ["", ""],
  deprecation_intangible__range: ["", ""],
  revaluations__range: ["", ""],
  wc_decrease__range: ["", ""],
  operating_interest_paid__range: ["", ""],
  operating_cash__range: ["", ""],
  capex_fixed__range: ["", ""],
  capex_intangible__range: ["", ""],
  asset_sale__range: ["", ""],
  investing_cash__range: ["", ""],
  capital_increase__range: ["", ""],
  debt_increase__range: ["", ""],
  financial_interest_paid__range: ["", ""],
  dividend__range: ["", ""],
  financing_cash__range: ["", ""],
};
