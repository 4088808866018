import { StyleSheet } from "react-native";

export default StyleSheet.create({
  wrapper: {
    height: "100%",
    maxWidth: 70,
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    maxHeight: 35,
    aspectRatio: 1,
    alignSelf: "center",
    // marginTop: "15%",
    height: "100%",
    resizeMode: "contain",
  },
  text: {
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    letterSpacing: 1,
    fontFamily: "Heebo_700Bold",
  },
});
