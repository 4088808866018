import { StyleSheet } from "react-native";

export default function useStyle() {
  return StyleSheet.create({
    container: {
      padding: 20,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    subHeaderText: {
      fontSize: 14,
      color: 'gray',
      marginBottom: 20,
    },
    inputPairContainer: {
      marginBottom: 20,
    },
    label: {
      fontSize: 16,
      fontFamily: 'Heebo_700Bold',
      marginBottom: 5,
    },
    input: {
      borderColor: "#6A7DA3",
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 16,
      padding: 5,
    },
    inputContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
    },

  });
}
