import React from "react";
import { Image, View, Text } from "react-native";
import useStyle from "./styles";

export default function HomeHeader() {
  const time = new Date();
  const styles = useStyle();
  return (
    <View style={styles.wrapper}>
      <Image
        style={styles.image}
        source={require("../../../../assets/images/logo.png")}
      />
      <Text style={styles.time}>
        {time.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </Text>
    </View>
  );
}
