import "expo-dev-client";
import i18n from "./i18n";
import { StatusBar } from "expo-status-bar";
import React, { useEffect } from "react";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import EStyleSheet from "react-native-extended-stylesheet";
import useCachedResources from "./hooks/useCachedResources";
import Navigation from "./navigation";
import { Dimensions, Image, View } from "react-native";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import {
  Heebo_700Bold,
  useFonts,
  Heebo_400Regular,
} from "@expo-google-fonts/heebo";

export default function App() {
  const isLoadingComplete = useCachedResources();
  let [fontsLoaded] = useFonts({
    Heebo_700Bold,
    Heebo_400Regular,
  });

  let { width } = Dimensions.get("window");
  EStyleSheet.build({
    $rem: width > 340 ? 18 : 16,
  });

  useEffect(() => {
    i18n.init();
  }, []);

  if (!isLoadingComplete || !fontsLoaded) {
    return null; // TODO: add loading component
  } else {
    return (
      <Provider store={store}>
        <SafeAreaProvider>
          <SafeAreaView
            edges={["right", "top", "left"]}
            style={{ flexGrow: 1, backgroundColor: "#f2f2f2" }}
          >
              <Image
                source={require("./assets/images/fundusze-europejskie.jpg")}
                style={{ width: "100%", height: 50, borderBottomWidth: 1, borderColor: "#1E2D42"}}
              />
            <Navigation />
            <StatusBar />
          </SafeAreaView>
        </SafeAreaProvider>
      </Provider>
    );
  }
}
