import { StyleSheet } from "react-native";
const stylesheet = StyleSheet.create({
    boldHeader: {
        paddingTop: 40,
        paddingBottom: 10,
        fontSize: 21,
        textAlign: "center",
        fontFamily: "Heebo_700Bold"
      }
});
export default stylesheet;