import React, { useCallback, useState, useEffect } from 'react';
import { Text, TextInput, useThemeColor } from '../components/Themed';
import { View, StyleSheet, Switch, Button, ScrollView, Dimensions, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { useTranslation } from 'react-i18next';
import { Picker } from '@react-native-picker/picker';
import { Random, generateBacktest } from '../helpers/datagen';
import { Table, Row, Rows } from 'react-native-table-component';
import SegmentedControl from './SegmentedControl'; // Import the custom component
import TextStyles from "../components/global/text-styles";
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine } from 'victory-native';

function extractCsvHeaders(csvContent: string, delimiter: string = ','): string[] {
  // Regular expression to match CSV headers considering quoted strings
  const regex = new RegExp(`(?:^|${delimiter})(\"(?:[^\"]+|\"\")*\"|[^${delimiter}]*)`, 'g');

  // Get the first line (header line)
  const firstLine = csvContent.split('\n')[0];

  // Extract headers using regex
  const headers = [];
  let match;
  while ((match = regex.exec(firstLine)) !== null) {
    // Remove quotes and add to headers array
    headers.push(match[1].replace(/^"|"$/g, '').replace(/""/g, '"'));
  }

  return headers;
}


interface QuantamentalDataset {
  name: string;
  variables: string[];
  rows: number[][];
}

const constructRandomDataset = (name: string, variables: string[]): QuantamentalDataset => {
  const colRand = variables.map((variable) => new Random(name, variable));
  const rows: number[][] = [
    variables.map((variable, idx) => colRand[idx].inRange(40, 80)),
  ];

  for (let i = 1; i < 12; i++) {
    const row: number[] = [];
    for (let j = 0; j < variables.length; j++) {
      const rand = colRand[j];
      row.push(rand.inRange(-3, 5) + rows[i - 1][j]!);
    }
    rows.push(row);
  }

  return {
    name,
    variables,
    rows,
  }
}

type ChartPoint = { x: string, y: number };

type BacktestChartData = {
  base: ChartPoint[],
  predicted: ChartPoint[],
}

const findDomain = (data: BacktestChartData) => {
  let allData = [...data.base, ...data.predicted];
  let yValues = allData.map(d => d.y);
  return [Math.min(...yValues) - 1, Math.max(...yValues) + 1]

}

const generateBacktestChartData = (dataset: QuantamentalDataset, targetVar: string, ...args: any[]): BacktestChartData | null => {
  // I guess we just use the following days as the x axis
  // and the values as the y axis

  const random = new Random(dataset.name, targetVar, ...args);
  const baseDate = new Date("2022-01-01").getTime();
  const dateAfter = (days: number) => {
    const date = new Date(baseDate + days * 24 * 60 * 60 * 1000);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }
  const cidx = dataset.variables.indexOf(targetVar);
  if (cidx === -1) return null;
  const base = dataset.rows.map((row, ridx) => ({ x: dateAfter(ridx), y: row[cidx] }));
  const dy = (random.inRange(-20, 25) / 100) * base[0].y;
  const predicted = base.map((point, pidx) => ({ x: point.x, y: ((100 + random.inRange(-5, 5)) / 100) * point.y + dy }));

  return {
    base,
    predicted,
  }
}

const QuantamentalScreen: React.FC = () => {
  const { t } = useTranslation();

  // State for date pickers
  const [endTrainingTime, setEndTrainingTime] = useState(new Date());
  const [endValidationTime, setEndValidationTime] = useState(new Date());
  const [isCrossValidation, setIsCrossValidation] = useState(false);
  const [isDenoiseWavelet, setIsDenoiseWavelet] = useState(false);
  const [isDenoiseWavelet2, setIsDenoiseWavelet2] = useState(false);
  const [isTrainWithGridsearch, setIsTrainWithGridsearch] = useState(false);
  const [isRegressionOrClassification, setIsRegressionOrClassification] = useState(false);
  const [backtestsData, setBacktestsData] = useState<BacktestChartData | null>(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const options = ['A', 'B', 'C', 'D', 'E'];

  const onSelectOption = (option: string) => {
    setSelectedOption(option);
    setModalVisible(false);
  };

  const [isDenoiseFourier, setIsDenoiseFourier] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState<string | null>(null);
  const [selectedTargetVariable, setSelectedTargetVariable] = useState<string | null>(null);

  const [uploadDatasetSectionOpen, setUploadDatasetSectionOpen] = useState(false);
  // ... other states for toggle switches

  const [fillOptions, setFillOptions] = useState<string>('');
  const [newDataset, setNewDataset] = useState<QuantamentalDataset | null>(null);

  const [datasets, setDatasets] = useState<QuantamentalDataset[]>([
    { name: t("quantamentumView.datasetOptions.noDataset"), variables: [], rows: [] },
    constructRandomDataset(t("quantamentumView.datasetOptions.technicalData"), ['Local market share', 'Abroad market share']),
    constructRandomDataset(t("quantamentumView.datasetOptions.sentimentData"), ['News signal', 'Social media signal']),
  ]);

  const selectedDatasetVariables = datasets.find((dataset) => dataset.name === selectedDataset)?.variables || [];
  const allVariables = selectedDatasetVariables;

  // Dummy handler functions (implement logic as needed)

  const handleTrainWithGridSearchPress = () => {
    const deps = [selectedDataset, selectedTargetVariable, selectedDataset, selectedTargetVariable, endTrainingTime, endValidationTime, isCrossValidation, isDenoiseWavelet, isDenoiseWavelet2, isTrainWithGridsearch, isRegressionOrClassification, isDenoiseFourier, fillOptions];
    const dataset = datasets.find(x => x.name == selectedDataset);
    if (!dataset || !selectedTargetVariable) return;
    const data = generateBacktestChartData(dataset, selectedTargetVariable, ...deps);
    setBacktestsData(data);

  };
  const dataColor = useThemeColor({}, "text");

  const featureImportances = allVariables.filter((variable) => variable !== selectedTargetVariable).map(variable => (
    {
      x: variable,
      y: Math.round(new Random(selectedDataset, selectedTargetVariable, variable).inRange(0, 10) * 100) / 100,
    }));

  const readFileWeb = (file: any) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const handleFileUpload = async () => {
    const separator = ';';
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.assets && result.assets.length > 0) {
      const file = result.assets[0];
      const content: string = Platform.OS === 'web' ? await readFileWeb(file.file) : await FileSystem.readAsStringAsync(file.uri);
      const contentLines = content.split('\n');
      const headersString = contentLines[0];
      const headers = extractCsvHeaders(headersString, separator);
      const rows: number[][] = [];

      for (let i = 0; i < contentLines.length - 1; i++) {
        const rowValues = contentLines[i + 1].split(separator);
        const row: number[] = [];
        for (let j = 0; j < headers.length; j++) {
          const floatValue = parseFloat(rowValues[j]);
          // ffill or 0
          row.push(!isNaN(floatValue) ? floatValue : (rows[i - 1] ? rows[i - 1][j] : 0));
        }
        rows.push(row);
      }
      setNewDataset({ name: 'New dataset', variables: headers, rows });
    }
  };

  useEffect(() => {
    if (newDataset) {
      setSelectedTargetVariable(newDataset.variables[0]);
    }
  }, [selectedDataset]);

  return (
    <ScrollView style={styles.container}>
      {/* Date Pickers */}
      <View style={styles.row}>
        {/* End Training Time */}
        <DateTimePicker
          value={endTrainingTime}
          mode="date"
          display="default"
          onChange={(event, date) => date && setEndTrainingTime(date)}
        />
        <DateTimePicker
          value={endValidationTime}
          mode="date"
          display="default"
          onChange={(event, date) => date && setEndValidationTime(date)}
        />
      </View>

      {/* Own datasets */}
      <Text style={styles.sectionTitle}>{t('quantamentumView.selectDataset')}</Text>
      <Picker
        selectedValue={selectedDataset}
        onValueChange={(itemValue, itemIndex) => setSelectedDataset(itemValue)}
      >
        {datasets.map((dataset) => (
          <Picker.Item label={dataset.name} value={dataset.name} />
        ))}
      </Picker>

      {
        // upload dataset section
        uploadDatasetSectionOpen && newDataset ?
          <>
            <View style={{
              //width: '80%',
              border: '1px solid black',
              padding: 20,
            }}>

              <Text key={"uploaddataset"} style={styles.sectionTitle}>{t('quantamentumView.uploadDatasetModal.title')}</Text>
              <TextInput 
                placeholder={t('quantamentumView.uploadDatasetModal.inputs.nameOfNewDataset')} 
                onChangeText={txt => setNewDataset({ ...newDataset, name: txt })} 
                value={newDataset.name} 
              />

              <View>
                {newDataset.variables.map((variable, index) => {
                  const placeholder = t('quantamentumView.uploadDatasetModal.inputs.variableName')
                  return <TextInput key={`${variable}-${index}`} placeholder={placeholder} value={newDataset.variables[index]} />
                })}
              </View>

              <Button 
                title={t('quantamentumView.uploadDatasetModal.button')} 
                onPress={() => {
                  setDatasets([...datasets, newDataset!])
                  setSelectedDataset(newDataset.name)
                  setUploadDatasetSectionOpen(false)
              }} />
            </View>
          </>
          :
          <Button 
            title={t('quantamentumView.uploadDatasetModal.button')} 
            onPress={() => { setNewDataset(null); setUploadDatasetSectionOpen(true); handleFileUpload(); }} 
          />
      }

      <Text style={styles.sectionTitle}>{t('quantamentumView.variablesOfSelectedDataset')}</Text>
      {
        // display variables of selected dataset
        selectedDataset && selectedDatasetVariables.length > 0 ? (
          <>
            {selectedDatasetVariables.map((variable) => (
              <Text>- {variable}</Text>
            ))}
          </>
        )
          :
          (<Text>{t("quantamentumView.noVariables")}</Text>)
      }

      {/* Select Prediction Details */}
      <Text style={styles.sectionTitle}>{t('quantamentumView.provideInformationText')}</Text>
      <TextInput placeholder={t('quantamentumView.inputs.predictionTime')} />
      <TextInput placeholder={t('quantamentumView.inputs.predictionTime')} />

      <TextInput placeholder={t('quantamentumView.inputs.modelWithWindow')} />
      <TextInput placeholder={t('quantamentumView.inputs.predictDeciles')} />
      <TextInput placeholder={t('quantamentumView.inputs.addMovingAverage')} />

      {/* Toggle Switches */}
      <View style={styles.switchRow}>
        <Text style={styles.switchLabel}>{t('quantamentumView.checkboxes.denoiseWithWaveletTransform')}</Text>
        <Switch
          value={isDenoiseWavelet}
          onValueChange={setIsDenoiseWavelet}
        />
      </View>

      <View style={styles.switchRow}>
        <Text style={styles.switchLabel}>{t('quantamentumView.checkboxes.denoiseWithFourierTransform')}</Text>
        <Switch
          value={isDenoiseWavelet2}
          onValueChange={setIsDenoiseWavelet2}
        />
      </View>

      <View style={styles.switchRow}>
        <Text style={styles.switchLabel}>{t('quantamentumView.checkboxes.includeGridSearchInTrainingValidationPhase')}</Text>
        <Switch
          value={isTrainWithGridsearch}
          onValueChange={setIsTrainWithGridsearch}
        />
      </View>

      <View style={styles.switchRow}>
        <Text style={styles.switchLabel}>{t('quantamentumView.checkboxes.includeWalkForwardCrossValidationInTrainingTheModel')}</Text>
        <Switch
          value={isCrossValidation}
          onValueChange={setIsCrossValidation}
        />
      </View>

      <View style={styles.switchRow}>
        <Text style={styles.switchLabel}>{t('quantamentumView.checkboxes.switchFromRegressionToClassification')}</Text>
        <Switch
          value={isRegressionOrClassification}
          onValueChange={setIsRegressionOrClassification}
        />
      </View>

      <View style={styles.container}>
        {/* ... existing components */}
        <Text style={styles.sectionTitle}>
          {t('quantamentumView.missingDataMethodToggle.title')}
        </Text>
        <SegmentedControl selectedOption={fillOptions} setSelectedOption={setFillOptions} />

        {/* ... remaining components */}
      </View>




      {/* 
      {/* Table
        <View style={styles.tableContainer}>
        <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
          <Row data={tableHead} style={styles.head} textStyle={styles.text} />
          <Rows data={tableData} textStyle={styles.text} />
        </Table>
      </View> */}

      {/* Select target variable */}
      <Text style={styles.sectionTitle}>{t('quantamentumView.selectTargetVariable.title')}</Text>
      <Picker
        selectedValue={null}
        onValueChange={(itemValue, itemIndex) => setSelectedTargetVariable(itemValue)}
      >
        {allVariables.map((variable) => (
          <Picker.Item 
            label={t(`quantamentumView.selectTargetVariable.options.${variable}`)}
            value={variable} />
        ))}
      </Picker>

      {/* Repeat for other toggle switches */}

      {/* Action Buttons */}
      <View style={{marginTop: 10, marginBottom:10}}>
        <Button title={t('quantamentumView.selectTargetVariable.button')} onPress={handleTrainWithGridSearchPress}/>
      </View>
      {
        // Feature importance table
        // display all variables except target variable
        selectedTargetVariable && (<View>
          <Text style={styles.sectionTitle}>{t('quantamentumView.charts.featureImportance.title')}</Text>
          <VictoryChart padding={{ left: 150, bottom: 50 }} horizontal domainPadding={20} style={{ parent: {} }}>
            <VictoryBar data={featureImportances} style={{
              data: { fill: "#181AD4" },
              parent: { border: "1px solid #ccc", backgroundColor: "#fff" },
            }} />
            <VictoryAxis
              style={{
                axis: { stroke: dataColor },
                ticks: { fill: dataColor, stroke: dataColor },
                tickLabels: { fill: dataColor },
              }}
              dependentAxis
            />
            <VictoryAxis
              style={{
                axis: { stroke: dataColor },
                ticks: { fill: dataColor, stroke: dataColor },
                tickLabels: { fill: dataColor },
              }}
            />

          </VictoryChart>


        </View>)}
      {

        backtestsData && (
          <View>
            <Text style={TextStyles.boldHeader}>{t('quantamentumView.charts.portfolioPerformance.title')}</Text>
            <VictoryChart domain={{ y: findDomain(backtestsData!) as any }}>
              <VictoryLine
                style={{
                  data: { stroke: "#181AD4", color: "#181AD4", },
                  parent: { border: "1px solid #ccc", backgroundColor: "#fff", paddingBottom: 40 },
                  labels: { fontSize: 50, color: dataColor },
                }}
                interpolation={"natural"}
                height={300}
                data={backtestsData.base}
              />
              <VictoryLine
                style={{
                  data: { stroke: "#00FF00", color: "#00FF00", },
                  parent: { border: "1px solid #ccc", backgroundColor: "#fff", paddingBottom: 40 },
                  labels: { fontSize: 50, color: dataColor },
                }}
                interpolation={"natural"}
                height={300}
                data={backtestsData.predicted}
              />
              <VictoryAxis
                style={{
                  axis: { stroke: dataColor },
                  tickLabels: { fill: dataColor },
                }}
                dependentAxis
              />
              <VictoryAxis
                tickCount={5}
                style={{
                  axis: { stroke: dataColor },
                  ticks: { fill: dataColor, stroke: dataColor },
                  tickLabels: { fill: dataColor, angle: -45 },
                }}
              />
            </VictoryChart>
          </View>
        )
      }
      <View style={{margin: "auto", width: "90%", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <Text>{t("quantamentumView.description")}</Text>
      </View>
    </ScrollView>




  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#003366', // Dark blue background
    padding: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 22,
    color: 'white',
    marginBottom: 10,
  },
  input: {
    backgroundColor: 'white',
    marginBottom: 10,
    paddingHorizontal: 10,
    height: 40,
    borderRadius: 4,
  },
  switchRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  switchLabel: {
    color: 'white',
    fontSize: 18,
  },
  tableContainer: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
    marginBottom: 20,
  },
  head: {
    height: 40,
    backgroundColor: '#f1f8ff'
  },
  text: {
    margin: 6
  },


  // ... Add styles for other components
});

export default QuantamentalScreen;
