import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import React from "react";
import { View } from "react-native";
import BottomTabIcon from "../BottomTabIcon";
import useStyles from "./styles";

export default function BottomTab(props: BottomTabBarProps) {
  const styles = useStyles();

  return (
    <View style={styles.wrapper}>
      {props.state.routes.map((route, index) => (
        <BottomTabIcon {...{ route, index, ...props }} key={index} />
      ))}
    </View>
  );
}
