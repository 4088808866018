import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import * as React from "react";
import LinkingConfiguration from "./LinkingConfiguration";
import RootNavigator from "./rootNavigator";
import { colors } from "../constants/Colors";

export default function Navigation() {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={{
        ...DefaultTheme,
        colors: { ...DefaultTheme.colors, background: colors.background },
      }}>
      <RootNavigator />
    </NavigationContainer>
  );
}
