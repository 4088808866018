import React from "react";
import { View, Text } from "react-native";
import useStyle from "./styles";
import { ButtonProps } from "../../../constants/interfaces";
import { Lato_400Regular, useFonts } from "@expo-google-fonts/lato";
import AppLoading from "expo-app-loading";

export default function Button({ text, variant }: ButtonProps) {
  const styles = useStyle();
  let [fontsLoaded] = useFonts({
    Lato_400Regular,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <View
      style={[
        styles.wrapper,
        { backgroundColor: variant === "primary" ? "#181AD4" : "#f2f2f2" },
      ]}>
      <Text
        style={[
          styles.text,
          {
            color: variant === "primary" ? "#f2f2f2" : "#181AD4",
            fontFamily: "Lato_400Regular",
          },
        ]}>
        {text}
      </Text>
    </View>
  );
}
