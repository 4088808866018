import React from "react";
import { View, Text } from "react-native";
import useStyle from "./styles";
import { BoxProps } from "../../../../constants/interfaces";

export default function Box({ name, children }: BoxProps) {
  const styled = useStyle();
  return (
    <View style={styled.box}>
      {children}
      <Text style={styled.boxText}>{name}</Text>
    </View>
  );
}
