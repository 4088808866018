import StyleSheet from "react-native-extended-stylesheet";
import { colors } from "../../../../constants/Colors";

export default function useStyle() {
  return StyleSheet.create({
    wrapper: {
      marginTop: 30,
      alignItems: "left",
      paddingHorizontal: 24,
      paddingBottom: 30,
    },
    image: {
      height: 20,
      width: 200,
      resizeMode: "cover",
      marginBottom: 15,
    },
    time: {
      color: colors.grey,
      fontSize: 18,
      fontFamily: "Heebo_700Bold",
      letterSpacing: 1,
    },
  });
}
