import React from "react";

import useColorScheme from "../hooks/useColorScheme";
import BottomTab from "../components/navigation/BottomTab";
import Colors from "../constants/Colors";
import HomeScreen from "../screens/HomeScreen";
import HomeHeader from "../components/screens/Home/Header";
import Header from "../components/navigation/Header";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { RootTabParamList } from "./types";

import QuantamentalScreen from "../screens/QuantamentalScreen";
import ScreenerScreen from "../screens/ScreenerScreen"; 
import DcfScreen from "../screens/DcfScreen";
import SettingsScreen from "../screens/SettingsScreen";
import SignInScreen from "../screens/SignInScreen";
import { useTranslation } from "react-i18next";

const Tab = createBottomTabNavigator<RootTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const {t } = useTranslation ();

  return (
    <Tab.Navigator
      initialRouteName="HomeScreen"
      tabBar={props => <BottomTab {...{ ...props }} />}
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}>
      <Tab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{ header: () => <HomeHeader /> }}
      />
      <Tab.Screen
        name="ScreenerScreen"
        component={ScreenerScreen}
        options={{
          header: () => (
            <Header
              title={t("screenerView.title")}
            />
          ),
        }}
      />
      <Tab.Screen
        name={"DCFScreen"}
        component={DcfScreen}
        options={{
          header: () => (
            <Header
              title={t("dcfView.title")}
              subtitle={t("dcfView.subtitle")}
            />
          ),
        }}
      />
      <Tab.Screen
        name={"QuantamentalScreen"}
        component={QuantamentalScreen}
        options={{
          header: () => (
            <Header
              title={t("quantamentumView.title")}
              subtitle={t("quantamentumView.title")}
            />
          ),
        }}
      />
      <Tab.Screen 
        name={"SettingsScreen"} 
        component={SettingsScreen} 
        options={{
          header: () => (
            <Header
              title={t("settingsView.title")}
            />
          ),
        }}
      />
      {/*<Tab.Screen name={"SignUpScreen"} component={SignUpScreen} />*/}
      <Tab.Screen
        name={"SignInScreen"}
        component={SignInScreen}
        options={{ header: () => null }}
      />
    </Tab.Navigator>
  );
}
