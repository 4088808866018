import { Backtest, CompanyData } from '../constants/interfaces';
import seedrandom from 'seedrandom';

const buildRng = (...args: any[]) => seedrandom(args.map(x => x?.toString()).join('$'));

export class Random {
    rng: seedrandom.PRNG;
    constructor(...args: any[]) {
        this.rng = buildRng(...args);
    }
    inRange(min: number, max: number) {
        return min + this.rng() * (max - min)
    }
    inRangeInt(min: number, max: number) {
        return Math.floor(this.inRange(min, max));
    }
    string(length: number) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(this.rng() * characters.length));
        }
        return result;
    };

    choice<T>(arr: ArrayLike<T>): T {
        return arr[Math.floor(this.rng() * arr.length)];
    }
    uniformShare(count: number) {
        const shares: number[] = [];
        let remaining = 1;
        for (let i = 0; i < count; i++) {
            const share = this.inRange(0, remaining);
            remaining -= share;
            shares.push(share);
        }
        shares[shares.length - 1] += remaining;
        return shares;
    
    }
}

export function generateScreenerCompanyData(companyName: string, ticker: string, period: number, ...args: any[]): CompanyData {
    // Use company name and period as seed
    const random = new Random(companyName, period, ...args);

    // Generate company data
    return {
        id: random.inRangeInt(1, 10000),
        name: companyName,
        ticker: ticker,
        weight: random.inRange(0, 1),
        is_in: random.choice(["S&P500", "NASDAQ", "DOWJONES", "FTSE100", "DAX"]),
        period: period,
        as_of: `${period}-12-31`,
        currency: random.choice(["USD", "EUR", "GBP", "JPY", "CNY"]),
        total_assets: random.inRange(10000, 1000000),
        fixed_assets: random.inRange(1000, 500000),
        tangible_assets: random.inRange(500, 300000),
        intangible_assets: random.inRange(500, 300000),
        goodwill: random.inRange(100, 100000),
        current_assets: random.inRange(500, 400000),
        inventories: random.inRange(100, 200000),
        short_term_receivables: random.inRange(100, 200000),
        cash: random.inRange(100, 150000),
        equity: random.inRange(1000, 500000),
        minority_interest: random.inRange(50, 50000),
        reserves_and_other: random.inRange(100, 100000),
        long_term_liabilities: random.inRange(1000, 400000),
        long_term_debt: random.inRange(500, 300000),
        long_term_lease: random.inRange(100, 100000),
        short_term_liabilities: random.inRange(500, 250000),
        short_term_trade_liabilities: random.inRange(100, 100000),
        short_term_debt: random.inRange(100, 100000),
        short_term_lease: random.inRange(50, 50000),
        revenue: random.inRange(5000, 800000),
        cogs: random.inRange(1000, 400000),
        sga_expense: random.inRange(500, 200000),
        other_operating_income: random.inRange(100, 100000),
        other_operating_expense: random.inRange(100, 100000),
        financial_income: random.inRange(100, 50000),
        financial_expense: random.inRange(50, 50000),
        other_income_before_tax: random.inRange(100, 50000),
        tax: random.inRange(100, 100000),
        off_PL_income: random.inRange(100, 50000),
        accrual_income: random.inRange(50, 50000),
        deprecation_tangible: random.inRange(100, 50000),
        deprecation_intangible: random.inRange(100, 50000),
        revaluations: random.inRange(-50000, 50000),
        wc_decrease: random.inRange(-50000, 50000),
        operating_interest_paid: random.inRange(100, 50000),
        operating_cash: random.inRange(1000, 200000),
        capex_fixed: random.inRange(500, 150000),
        capex_intangible: random.inRange(100, 100000),
        asset_sale: random.inRange(100, 100000),
        investing_cash: random.inRange(-100000, 200000),
        capital_increase: random.inRange(1000, 100000),
        debt_increase: random.inRange(1000, 150000),
        financial_interest_paid: random.inRange(100, 50000),
        dividend: random.inRange(100, 50000),
        financing_cash: random.inRange(-100000, 200000),
    };
  }

export function generateBacktest(period: number, ...args: any[]): Backtest {

    const random = new Random(period, ...args);

    let output: Backtest = {};
    let baseValue = random.inRange(50, 100);
    for(let i = 1; i <= 12; i++) {
        baseValue += random.inRange(-4, 6);
        output[`${period}-${i.toString().padStart(2, '0')}`] = baseValue; 
    }
    return output;
}