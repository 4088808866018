import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signOut as firebaseSignOut, getAuth } from "firebase/auth";
import { handleAuthStateChange, refreshToken } from "./thunks";
import { AuthState } from "./interfaces";


const initialState: AuthState = {
    user: null,
    token: "",
    isLoading: true,  
};


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOut(state) {
      firebaseSignOut(getAuth());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleAuthStateChange.pending, (state) => {
      if (!state.user) state.isLoading = true;
    });
    builder.addCase(handleAuthStateChange.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.user = payload.user;
        state.token = payload.token;
      } else {
        state.user = null;
        state.token = "";
      }
    });
    builder.addCase(handleAuthStateChange.rejected, (state) => {
      state.isLoading = false;
      state = initialState;
    });
    builder.addCase(refreshToken.pending, () => {});
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(refreshToken.rejected, () => {});
  },
});



export default authSlice.reducer;

export const signOut = authSlice.actions.signOut;