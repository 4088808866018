import React from "react";
import { Text, View } from "react-native";
import { Lato_400Regular, useFonts } from "@expo-google-fonts/lato";
import AppLoading from "expo-app-loading";
import { StyledText } from "../../StyledText";
import useStyles from "./styles";
import { HeaderProps } from "../../../constants/interfaces";

export default function Header({ title, subtitle }: HeaderProps) {
  const styles = useStyles();
  let [fontsLoaded] = useFonts({
    Lato_400Regular,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <View>
      {title && (
        <StyledText style={[styles.title, { fontFamily: "Lato_400Regular" }]}>
          {title}
        </StyledText>
      )}
      {/* <View style={styles.lineWrapper}> */}
      {subtitle && (
        <StyledText style={[styles.subTitle, { fontFamily: "Lato_300Light" }]}>
          {subtitle}
        </StyledText>
      )}
      {/* <View style={styles.line} /> */}
      {/* </View> */}
    </View>
  );
}
