import { useState } from "react";
import EStyleSheet from "react-native-extended-stylesheet";

import { ScrollView, Text, Button, View } from "react-native";
import { TextInput } from '../components/Themed';
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from 'react-i18next';

export default function SettingsScreen() {

  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  
  const { t, i18n } = useTranslation();
  
  return (
    <ScrollView style={styles.container}>
      <Text style={{color: 'white', fontSize: 24}}>{t("settingsView.changeLanguage")}</Text>
      <Picker
        selectedValue={i18n.language}
        onValueChange={(itemValue, itemIndex) => i18n.changeLanguage(itemValue)}
      >
        <Picker.Item label="English" value="en" />
        <Picker.Item label="Polski" value="pl" />
        <Picker.Item label="Deutsch" value="de" />
        <Picker.Item label="Español" value="es" />
      </Picker>
      
      <Text style={{color: 'white', fontSize: 24}}>{t("settingsView.changePassword")}</Text>
      <TextInput style={styles.TextInput} value={newPassword1} onChangeText={t => setNewPassword1(t)} secureTextEntry={true} placeholder={t("settingsView.newPassword")} />
      <TextInput value={newPassword2} onChangeText={t => setNewPassword2(t)} secureTextEntry={true} placeholder={t("settingsView.confirmNewPassword")} />
      {newPassword1 != newPassword2 && !!newPassword1 && <Text style={{color: 'white'}}>{t("settingsView.passwordsDoNotMatch")}</Text>}
      
      <View style={styles.button}>
        <Button disabled={newPassword1 != newPassword2} title={t("settingsView.newPassword")} onPress={() => {}} />
      </View>
      <View style={styles.button}>
        <Button title={t("settingsView.deleteAccount")} onPress={() => alert('Account deleted')} />
      </View>
      <View style={styles.button}>
        <Button title={t("settingsView.logOut")} onPress={() => alert('Logged out')} />
      </View>
    </ScrollView>
  );
}

const styles = EStyleSheet.create({
  container: {
    padding: ".8rem",
  },
  input: {
    borderRadius: 16,
  },
  button: {
    margin: 5
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    color: 'white'
  }

});
