import http from "../helpers/http";
import { URL_API } from "react-native-dotenv";

export default {
  getFilteredCompanies(value: { [k: string]: number }) {
    try {
      return http(`${URL_API}/company/filter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
    } catch (e) {
      console.log(e);
    }
  },

  getBacktest(value: {
    company_filter_schema: { [k: string]: number };
    start_dt: string;
    position_duration: number;
  }) {
    try {
      return http(`${URL_API}/backtest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
    } catch (e) {
      console.log(e);
    }
  },

  getCompanies() {
    try {
      return http(`${URL_API}/company`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      console.log(e);
    }
  },

  getResultDcf(value: { company_id: number; period__range: number[] }) {
    try {
      return http(`${URL_API}/company/resultsdcf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
    } catch (e) {
      console.log(e);
    }
  },

  getDcf(value: { company_id: number; period__range: number[] }) {
    try {
      return http(`${URL_API}/dcf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
    } catch (e) {
      console.log(e);
    }
  },

  getFilteredPrice(value: { company_id__in: number[]; time__range: string[] }) {
    try {
      return http(`${URL_API}/price/filter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
    } catch (e) {
      console.log(e);
    }
  },
};
