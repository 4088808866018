import React from "react";
import AppLoading from "expo-app-loading";
import { Text, TextProps } from "./Themed";
import { Lato_300Light, useFonts } from "@expo-google-fonts/lato";

export function StyledText(props: TextProps) {
  let [fontsLoaded] = useFonts({
    Lato_300Light,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <Text {...props} style={[{ fontFamily: "Lato_300Light" }, props.style]} />
  );
}
