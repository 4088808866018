import StyleSheet from "react-native-extended-stylesheet";

export default function useStyle() {
  return StyleSheet.create({
    wrapper: {
      borderColor: "#181AD4",
      borderWidth: 1,
      borderStyle: "solid",
      padding: 15,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 6,
      marginBottom: "0.84 rem",
    },
    text: {
      fontSize: 18,
    },
  });
}
