import { Company, StateProps } from "../constants/interfaces";
import data from '../data.json'

export function getCompanies(): Company[] {
  let seenCompanies = new Set();
  let companies: Company[] = []
  data.data.forEach(row => {
    const ticker = row[0];
    if (seenCompanies.has(ticker)) {
      return;
    }
    seenCompanies.add(row[0])
    companies.push({
      id: seenCompanies.size,
      name: String(row[0]),
      ticker: String(row[0]),
      is_in: "USA",
      country_id: 0
    })
  })
  return companies;
}

export const companies = getCompanies();

const sampleState: StateProps = {
  backtest: {
    "2020-01": 50,
    "2020-02": 80,
    "2020-03": 100,
    "2020-04": 150,
    "2020-05": 100,
    "2020-06": 200,
    "2020-07": 150,
    "2020-08": 100,
    "2020-09": 120,
    "2020-10": 150,
    "2020-11": 200,
    "2020-12": 250,
  },
  companies: [
    {
      id: 1,
      name: "Company A",
      ticker: "COMP_A",
      is_in: "USA",
      country_id: 101
    },
    {
      id: 2,
      name: "Company B",
      ticker: "COMP_B",
      is_in: "USA",
      country_id: 101
    },
    {
      id: 3,
      name: "Company C",
      ticker: "COMP_C",
      is_in: "USA",
      country_id: 101
    }
  ],
  companiesFiltered: [],
  loading: true,
  resultsDcf: [
    {
      company_id: 1,
      period: 2020,
      sales: 1000000,
      ebit: 200000,
      tax_payment: 50000,
      amortization: 10000,
      net_working_capital: 200000,
      current_assets: 500000,
      noncurrent_assets: 500000,
      current_liabilities: 200000,
      noncurrent_liabilities: 300000,
      financial_costs: 10000,
      capex: 50000,
      equity: 200000,
      debt: 300000
    },
    {
      company_id: 2,
      period: 2020,
      sales: 2000000,
      ebit: 400000,
      tax_payment: 100000,
      amortization: 20000,
      net_working_capital: 400000,
      current_assets: 1000000,
      noncurrent_assets: 1000000,
      current_liabilities: 400000,
      noncurrent_liabilities: 600000,
      financial_costs: 20000,
      capex: 100000,
      equity: 400000,
      debt: 600000
    },
    {
      company_id: 3,
      period: 2020,
      sales: 3000000,
      ebit: 600000,
      tax_payment: 150000,
      amortization: 30000,
      net_working_capital: 600000,
      current_assets: 1500000,
      noncurrent_assets: 1500000,
      current_liabilities: 600000,
      noncurrent_liabilities: 900000,
      financial_costs: 30000,
      capex: 150000,
      equity: 600000,
      debt: 900000
    }
  ],
  filteredPrice: [],
  dcf: "",
}

const emptyState: StateProps = {
  backtest: {},
  companies: [],
  companiesFiltered: [],
  loading: true,
  resultsDcf: [],
  filteredPrice: [],
  dcf: "",
};

export const initialState: StateProps = emptyState;
