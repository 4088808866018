import React from "react";
import { View } from "react-native";
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useAppSelector } from "../../../../redux/hooks";
import Header from "../../../navigation/Header";

export default function ChartDCF() {
  const { filteredPrice } = useAppSelector(state => state.screener);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = filteredPrice.map(el => new Date(el.time).toLocaleString());
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset",
        data: filteredPrice.map(el => el.price),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const config = {
    type: "line",
    data,
    options,
  };

  return (
    <View>
      {filteredPrice.length ? (
        <>
          <Header subtitle="Stock price (PLN)" />
          <View style={{ marginTop: 2 }} />
          <Line {...config} />
        </>
      ) : null}
    </View>
  );
}
