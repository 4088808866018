import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locale/en.json';
import pl from './locale/pl.json';
import de from './locale/de.json';
import es from './locale/es.json';

const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

console.log('initialized');

export default i18n;