import StyleSheet from "react-native-extended-stylesheet";

export default function useStyle() {
  return StyleSheet.create({
    elementWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "1.875rem",
    },
    inputsWrapper: {
      flexDirection: "row",
      alignItems: "center",
    },

    label: {
      fontSize: 16,
      fontFamily: 'Heebo_700Bold',
      marginBottom: 5,
    },
    input: {
      borderColor: "#6A7DA3",
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 16,
      padding: 5,
    },
    inputContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  });
}
