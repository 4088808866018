const tintColorLight = "#181ADD";
const tintColorDark = "#fff";
const background = "#1E2D42";
const backgroundNavbar = "#2F3E53";
const stroke = "#364666";
const stroke2 = "#6A7DA3";
const grey = "#AEBBCE";
const green = "#41D9B6";
const greenHover = "#52EAC7";
const greenPressed = "#63FBD8";
const red = "#FC295B";
const redPressed = "#FF668A";
const white = "#fff";

export const colors = {
  background,
  backgroundNavbar,
  stroke,
  stroke2,
  grey,
  green,
  greenHover,
  greenPressed,
  red,
  redPressed,
  white,
};
export default {
  light: {
    text: "#000",
    background: "#1E2D42",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#1E2D42",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};
